// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-game-jsx": () => import("./../../../src/pages/game.jsx" /* webpackChunkName: "component---src-pages-game-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-modulelist-jsx": () => import("./../../../src/templates/modulelist.jsx" /* webpackChunkName: "component---src-templates-modulelist-jsx" */),
  "component---src-templates-modules-jsx": () => import("./../../../src/templates/modules.jsx" /* webpackChunkName: "component---src-templates-modules-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

